import getConfig from 'next/config'

import getLogger from '@src/Logger'
import { CheckPaymentQuery } from '@gql/__generated__'

const { INTERCOM } = getConfig().publicRuntimeConfig

const dataLayerProp = 'dataLayer'

const callUnitInSeconds = 15 * 60

class Analytics {
  private room: AnalyticsRoom = null

  private user: AnalyticsUser | null = null

  public getCallUnits(durationInSeconds: number) {
    const callUnits = Math.round(durationInSeconds / callUnitInSeconds)
    return callUnits || 1
  }

  pushEvent(eventId: AnalyticsEventId, data: any = {}) {
    try {
      this.event(eventId, data)
    } catch (err) {
      getLogger().error({ err }, 'analytics pushEvent failed')
    }
  }

  event(eventId: AnalyticsEventId, data: any = {}) {
    let realEventId: string = eventId
    const isTutor = this.user && this.user.roles && this.user.roles.includes('tutor')
    const isStudent = this.user && this.user.roles && this.user.roles.includes('student')
    if (pageViewEvents.includes(eventId)) {
      let eventPage = '/' + eventId
      if (eventId.includes('topup/') && (data.miniCourse || data.giftVoucher)) {
        eventPage += '/' + (data.miniCourse || data.giftVoucher)
      }
      this.pushPageview(eventPage)
    }
    if (isTutor) {
      realEventId = `tutor-${eventId}`
    }
    const dlPush = {
      event: realEventId,
      role: isTutor ? 'tutor' : isStudent ? 'student' : 'visitor',
      user: this.user,
      room: this.room,
      ...data,
    }

    getLogger().info({ obj: { dlPush } }, `Analytics event ${eventId}`)

    // optionally disable intercom in local dev mode
    if (!INTERCOM) return

    window[dataLayerProp].push(dlPush)
  }

  pushPageview(url = window.location && window.location.pathname) {
    this.pushEvent('pageview', { url })
  }

  setRoom(value: AnalyticsRoom) {
    getLogger().info({ obj: { value } }, 'Analytics set room data')
    this.room = value
  }

  setUser(user: AnalyticsUser) {
    this.user = user
  }
}

const pageViewEvents: AnalyticsEventId[] = [
  'permission-test-started',
  'permission-test-success',
  'permission-test-failed',
  'topup/init',
  'topup/pay',
  'topup/cancelled',
  'topup/success',
]

const analytics = new Analytics()

export default analytics

export const getProductFromPayment = (payment: CheckPaymentQuery['payment']) => {
  if (!payment.targetType) {
    return {
      id: null,
      name: 'Payment',
      variant: payment.amount,
      category: 'payment',
      price: payment.amount,
      quantity: 1,
    }
  }
  if (payment.targetType === 'PACKAGE') {
    if (!payment.package) {
      getLogger().error({ payment }, `Payment with targetType ${payment.targetType} has no package`)
      return null
    }

    return {
      id: payment.package.id,
      name: payment.package.name,
      category: 'mini_course',
      price: payment.amount,
      quantity: 1,
    }
  }
  if (payment.targetType === 'GIFT_VOUCHER') {
    return {
      id: payment.targetId || null,
      name: 'Gift Card',
      category: 'gift_voucher',
      variant: payment.amount,
      price: payment.amount,
      quantity: 1,
    }
  }

  getLogger().error({ payment }, 'Unknown payment.targetType')
  return null
}

export type AnalyticsEventId =
  // General pageview
  | 'pageview'
  // Add to cart event
  | 'addToCart'
  // Checkout for ecommerce
  | 'checkout'
  // GA purchase
  | 'session-registration'
  // User filled his / her profile
  | 'create-profile/finished'
  // Tutor preview in modal
  | 'tutor/modal'
  // Invite screen in modal
  | 'tutor/modal/duration'
  // Invite to call sent by student
  | 'invite/sent'
  // Invite to call cancelled by student
  | 'invite/cancelled'
  // Invite to call expired
  | 'invite/expired'
  // Incoming call shown to tutor
  | 'incoming-call'
  // Incoming call accepted by tutor
  | 'incoming-call/accepted'
  // Incoming call rejected by tutor
  | 'incoming-call/rejected'
  // Incoming call cancelled by student
  | 'incoming-call/cancelled'
  // Incoming call not picked up in time
  | 'incoming-call/expired'
  // User entered the call
  | 'call'
  // Call cancelled due to some error
  | 'call/cancelled'
  // Call has been prolonged by student
  | 'call/prolonged'
  // Call ended because counter expired or hanged by user
  | 'call/ended'
  // Call ended, rating page shown to user
  | 'call/rating'
  // Call ended, summary page shown to user
  | 'call/summary'
  // Payment for call requested on user
  | 'call/payment'
  // User clicked on campaign promo
  | 'campaign/promo/click'
  // User subscribed for notification (of certain type)
  | 'notification/subscribe'
  // User visited tutors page
  | 'tutors'
  // User visited home page
  | 'home'
  // User switched the locale
  | 'locale/change'
  // User switched the currency
  | 'currency/change'
  // User visited login page
  | 'login'
  // User finished login
  | 'login/finished'
  // User visited signup page
  | 'signup'
  // User completed first step of signup
  | 'registration-success'
  // User completed signup, chose password and verified e-mail
  | 'registration-verified'
  // User visited dashboard page
  | 'dashboard'
  // User started cam/mic test
  | 'permission-test-started'
  // Permissions successfully granted and permission test passed
  | 'permission-test-success'
  // User fails cam/mic test after selecting call duration
  | 'permission-test-failed'
  // User clicked on 'chat with tutor' button in tutor list
  // Promo code redemption failed
  | 'promoCode/fail'
  // Promo code redemption succeed
  | 'promoCode/success'
  // User clicked on "start with chat" button
  | 'start-with-chat'
  // Chat started, user navigated to chat
  | 'start-with-chat/success'
  // User visited voucher page
  | 'voucher'
  // User redeemed voucher
  | 'voucher/success'
  // User entered wrong voucher code
  | 'voucher/fail'
  // Tutor check on notification permission
  | 'notification-permission'
  // User updated his / her profile
  | 'update-profile/finished'
  // User visited sodexo page
  | 'sodexo'
  // User visited edenred page
  | 'edenred'
  // User visited confirm sodexo page
  | 'confirm-sodexo'
  // User visited sodexo error page
  | 'error-sodexo'
  // Init sodexo payment success
  | 'initSodexoPayment/success'
  // Init sodexo payment failure
  | 'initSodexoPayment/fail'
  // Init benefit plus payment success
  | 'initBenefitPlusPayment/success'
  // Init benefit plus payment fail
  | 'initBenefitPlusPayment/fail'
  // User navigated to next question in survey
  | 'quiz/next'
  // User finished all questions in surveys
  | 'quiz/finished'
  // top up initialized
  | 'topup/init'
  // top up pay button triggers
  | 'topup/pay'
  // top up cancelled before the start
  | 'topup/cancelled'
  // top up cancelled or failed (in payment gateway)
  | 'topup/failed'
  // topup success
  | 'topup/success'
  // forgottenPassword opened
  | 'forgotten-password/init'
  // filled out forgotten password form
  | 'forgotten-password/requested'
  // error in forgotten password form
  | 'forgotten-password/fail'
  // user successfully verified token from email
  | 'forgotten-password/reset-success'
  // user visited reset-password page with invalid token
  | 'forgotten-password/invalid-token'
  // error on reset-password
  | 'forgotten-password/reset-fail'
  // user successfully changed his forgotten password
  | 'forgotten-password/change-password-success'
  // fail when changing forgotten password
  | 'forgotten-password/change-password-fail'
  // User updated his / her billing info
  | 'billing-info/finished'
  // User updated his / her phone info
  | 'phone-form/finished'
  // When user gets reward for referring another user - only on BE
  | 'referrer/reward'
  // When user signs up through referral - only on BE
  | 'referral/signup'
  // Before-install prompt displayed to user
  | 'appInstall/beforePrompt'
  // Before-install prompt closed by user
  | 'appInstall/beforePromptDismissed'
  // Install prompt displayed to user
  | 'appInstall/prompt'
  // Install prompt accepted by user, app started to install
  | 'appInstall/promptAccepted'
  // Install prompt closed by user, app not allowed to install
  | 'appInstall/promptDismissed'
  // App was successfully installed
  | 'appInstall/installed'
  // Trial lead message displayed to student
  | 'trialLeadMessage'
  // Student opened trial lead message
  | 'trialLeadMessage/open'
  // Student replied to trial lead message
  | 'trialLeadMessage/reply'
  // User has entered /coupon page
  | 'coupon'
  // User has redeemed a coupon
  | 'coupon/success'
  // User has failed to redeem a coupon
  | 'coupon/fail'
  // User purchase was processed successfully
  | 'purchase/success'

export type AnalyticsRoom = any

export type AnalyticsUser = any
