import { ServerError } from 'apollo-link-http-common'
import { WARN } from 'browser-bunyan'
import { GraphQLError } from 'graphql'

import ignoredErrorCodes from './ignoredErrorCodes'
import { Middleware } from './Logger'
import { hasServerErrorExtension, isFetchError, isServerError } from './Utils'

/**
 * Catches certain errors and reduces their report level to WARN or lower.
 */
const ignoreErrorMiddleware: Middleware = (rec, noemit, next) => {
  let ignore = false

  // Skips GraphQLError by code
  if (rec && rec.err && typeof rec.err.extensions === 'object' && rec.err.extensions.code) {
    const error: GraphQLError = rec.err
    ignore = shouldIgnoreErrorCode(error.extensions.code)
    // Skips ServerError by code
  } else if (rec && rec.err && isServerError(rec.err) && hasServerErrorExtension(rec.err)) {
    const error: ServerError = rec.err
    const errorRecords = error.result.errors
    ignore = Boolean(
      Array.isArray(errorRecords) &&
        errorRecords.length === 1 &&
        errorRecords[0] &&
        errorRecords[0].extensions &&
        errorRecords[0].extensions.code &&
        shouldIgnoreErrorCode(errorRecords[0].extensions.code),
    )

    /**
     * Skips error thrown by browser/Node's fetch()
     * We presume that:
     * 1) A fetch() promise only rejects when a network error is encountered
     * 2) A fetch() promise does not reject on HTTP errors (404, etc.)
     * See https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/fetch
     */
  } else if (rec && rec.err && isFetchError(rec.err)) {
    ignore = true
  }

  if (ignore) {
    const spawnedRec = { ...rec, level: Math.min(WARN, rec.level) }
    next(spawnedRec, noemit)
    return
  }

  next(rec, noemit)
}

const shouldIgnoreErrorCode = (errorCode: string) => {
  return Boolean(~ignoredErrorCodes.indexOf(errorCode))
}

export default ignoreErrorMiddleware
