import { ServerError } from 'apollo-link-http-common'

import { Middleware } from './Logger'
import { isServerError, hasServerErrorExtension } from './Utils'

/**
 * Catches apollo-client's ServerError and spawns new log record for each error
 * returned in JSON response (if any). Those errors are more descriptive
 * than original ServerError "Response not successful: Received status code XXX"
 */
const serverErrorMiddleware: Middleware = (rec, noemit, next) => {
  if (rec && rec.err && isServerError(rec.err) && hasServerErrorExtension(rec.err)) {
    const error: ServerError = rec.err
    const { result } = error

    // @ts-ignore
    error.origMessage = error.message
    // Spawn the new log record for each error from JSON response
    result.errors.forEach(errorRecord => {
      // Better error message
      error.message = errorRecord.message
      // Each spawned error contains just 1 error record
      error.result = {
        ...error.result,
        errors: [errorRecord],
      }
      const spawnedRec = { ...rec, err: error }
      next(spawnedRec, noemit)
    })
    return
  }

  next(rec, noemit)
}

export default serverErrorMiddleware
