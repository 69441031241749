/* eslint-disable @typescript-eslint/no-var-requires,no-console */
// NOTE: This require will be replaced with `@sentry/browser` when
// isBrowser === true thanks to the webpack config in next.config.js
const Sentry = require('@sentry/node')
const SentryIntegrations = require('@sentry/integrations')
const { detect, BrowserInfo } = require('detect-browser')
import getConfig from 'next/config'

const { NODE_CONFIG_ENV } = getConfig().publicRuntimeConfig

const isBrowser = (process as any).browser
const logRocket = typeof window !== 'undefined' && (window as any).LogRocket
// Cannot separate this helper
// We need to rewrite whole Next server and related stuff to Typescript
const isSupported = userAgent => {
  const browser = detect(userAgent)

  if (browser && typeof browser === 'object' && browser instanceof BrowserInfo) {
    if (browser.name === 'edge') {
      return false
    }
    if (browser.name === 'ie') {
      return false
    }
  }

  return true
}

const sentryOptions = {
  dsn: 'https://72bb2f3e3dad4529b4fb03f03d02bc18@o357563.ingest.sentry.io/5378338',
  environment: NODE_CONFIG_ENV,
  release: process.env.COMMIT_SHA,
  maxBreadcrumbs: NODE_CONFIG_ENV === 'development' ? 0 : 50,
  attachStacktrace: true,
  integrations: [],
  blacklistUrls: [
    /graph\.facebook\.com/i,
    /connect\.facebook\.net/i,
    /extensions\//i,
    /^chrome:\/\//i,
    /stats\.g\.doubleclick\.net/i,
    /www\.google-analytics\.com/i,
    /noembed\.com/i,
    /intercomcdn\.com/i,
    /paypal\.com/i,
    /lr-ingest\.io/i, // LogRocket recording service
  ],
  beforeSend: ev => {
    // Event enriched with request data here https://github.com/getsentry/sentry-javascript/blob/master/packages/node/src/handlers.ts#L216
    const userAgent = !isBrowser ? ev.request.headers['user-agent'] : navigator.userAgent
    const supported = isSupported(userAgent)
    // Returning null will cause the event to be dropped
    return supported ? ev : null
  },
  transport: undefined,
}

// When we're developing locally
if (NODE_CONFIG_ENV !== 'production' && NODE_CONFIG_ENV !== 'staging') {
  // Don't actually send the errors to Sentry
  sentryOptions.beforeSend = () => null

  // Instead, dump the errors to the console (for server and client)
  sentryOptions.integrations.push(
    new SentryIntegrations.Debug({
      // Triggers console.log not DevTools debugger
      debugger: false,
    }),
  )
}

sentryOptions.integrations.push(new SentryIntegrations.ExtraErrorData())

Sentry.init(sentryOptions)

const withSentryContext = (scope, sentryCtx) => {
  // Using Sentry context structure here
  // https://docs.sentry.io/enriching-error-data/context/?platform=node
  const { extra, level, tags, user } = sentryCtx

  if (level) {
    scope.setLevel(level)
  }

  if (user) {
    scope.setUser(user)
  }

  if (tags) {
    Object.keys(tags).forEach(key => {
      scope.setTag(key, tags[key])
    })
  }

  if (extra) {
    Object.keys(extra).forEach(key => {
      scope.setExtra(key, extra[key])
    })
  }

  scope.setExtra('onLine', isBrowser && typeof navigator.onLine === 'boolean' ? navigator.onLine : true)

  if (isBrowser && logRocket) {
    scope.setExtra('sessionURL', logRocket.sessionURL)
  }

  return scope
}

const configureScopeFromNextCtx = ctx => {
  Sentry.configureScope(scope => {
    const { req, res, query, pathname } = ctx

    scope.setTag('ssr', !isBrowser)
    scope.setExtra('query', query)
    scope.setExtra('pathname', pathname)

    if (req) {
      scope.setExtra('url', req.url)
      scope.setExtra('method', req.method)
      scope.setExtra('headers', req.headers)
      scope.setExtra('params', req.params)
    }

    if (res) {
      scope.setExtra('statusCode', res.statusCode)
    }
  })
}

const captureException = (err, sentryCtx = {}) => {
  let eventId = null

  Sentry.withScope(scope => {
    if (err.message) {
      // De-duplication currently doesn't work correctly for SSR / browser errors
      // so we force deduplication by error message if it is present
      // https://docs.sentry.io/data-management/event-grouping/
      scope.setFingerprint([err.message])
    }

    if (err.statusCode) {
      scope.setExtra('statusCode', err.statusCode)
    }

    withSentryContext(scope, sentryCtx)

    // todo fix js logger: getLogger().error({ err, stack: err.stack ? err.stack().toString() : undefined }, 'sentry capture exception')
    // In development env events are already logged using SentryIntegrations.Debug
    if (NODE_CONFIG_ENV === 'production' || NODE_CONFIG_ENV === 'staging') {
      console.error('sentry captureException', err, sentryCtx)
    }

    eventId = Sentry.captureException(err)
  })

  return eventId
}

const captureMessage = (msg, level, sentryCtx = {}) => {
  let eventId = null

  Sentry.withScope(scope => {
    withSentryContext(scope, sentryCtx)
    eventId = Sentry.captureMessage(msg, level)
  })

  // In development env events are already logged using SentryIntegrations.Debug
  if (NODE_CONFIG_ENV === 'production' || NODE_CONFIG_ENV === 'staging') {
    console.error('sentry captureMessage', msg, level, sentryCtx)
  }

  return eventId
}

const makeSentry = () => {
  return {
    Sentry,
    configureScopeFromNextCtx,
    captureException,
    captureMessage,
  }
}

export default makeSentry
