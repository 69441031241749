const ignoredErrorCodes = [
  'ANOTHER_VOUCHER_ALREADY_REDEEMED',
  'LOGIN_NOT_FOUND',
  'INVALID_PASSWORD',
  'INSUFFICIENT_FUNDS',
  'EMAIL_NOT_FOUND',
  'USER_ALREADY_EXISTS',
  'USER_ALREADY_EXISTS_GOOGLE',
  'PROBLEM_WITH_SIGNUP',
  'SUBSCRIPTION_ALREADY_EXISTS',
  'ROOM_CANCELLED',
  'VOUCHER_EXPIRED',
  'VOUCHER_NOT_FOUND',
  'VOUCHER_ALREADY_REDEEMED',
  'VOUCHER_REDEEM_MAX_LIMIT',
  'TUTOR_BUSY',
  'NO_SUCH_USER',
  'ONLY_GOOGLE_CREDENTIAL',
  'VERIFY_HASH_FAIL',
  'DATE_IN_PAST',
  'NO_ENTITY_FOUND',
  'TOKEN_EXPIRED',
  'TUTOR_IS_OFFLINE',
  'USER_ALREADY_HAD_CALL',
  'USER_PART_OF_REFERRAL',
  'CANNOT_FIND_GIFT_VOUCHER_TYPE',
  'INVALID_EXPIRATION_INPUT',
  'GOOGLE_AUTH_FAIL',
  'CONVERSATION_BLOCKED',
  'CONVERSATION_EXPIRED',
  'USER_BANNED',
  'NO_SUCH_CREDENTIAL',
  'CONTACT_ALREADY_VERIFIED',
  'REDEEM_PROMO_AS_VOUCHER',
  'TUTOR_HAS_UPCOMING_SCHEDULED_CALL',
  'ALREADY_REDEEMED',
  'CANNOT_CANCEL_PAST_SHIFT',
  'CANNOT_CANCEL_SHIFT_WITH_CALLS',
  'UNAUTHORIZED_CONVERSATION_ACCESS',
]

export default ignoredErrorCodes
