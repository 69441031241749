// https://michalzalecki.com/why-using-localStorage-directly-is-a-bad-idea/
import getLogger from '@src/Logger'

let inMemoryStorage: { [key: string]: string } = {}

const isSupported = () => {
  try {
    const testKey = 'vmK3yD2WJSUy5Zrb27Mb22wRqgvPvHP^e7R2zGdJ#eWZuvTKby4abDW2cCvjY!*hYEAw2Ba'
    localStorage.setItem(testKey, testKey)
    localStorage.removeItem(testKey)
    return true
  } catch (e) {
    getLogger().warn({ obj: { e } }, 'Local storage not supported')
    return false
  }
}

export const clear = (): void => {
  if (isSupported()) {
    localStorage.clear()
  } else {
    getLogger().warn(`Using inMemoryStorage for clear`)
    inMemoryStorage = {}
  }
}

export const getItem = (name: string): string | null => {
  if (isSupported()) {
    return localStorage.getItem(name)
  }
  if (inMemoryStorage.hasOwnProperty(name)) {
    getLogger().warn(`Using inMemoryStorage for getItem, key: ${{ name }}`)
    return inMemoryStorage[name]
  }
  return null
}

export const getItemTtl = <Value = any>(name: string): Value | null => {
  try {
    const data = JSON.parse(getItem(name))

    if (data && typeof data === 'object' && typeof data.value !== 'undefined') {
      // Intentionally using Date.now() instead of server-synced time.
      // getItemTtl() / setItemTtl() are compared against each other. So everything
      // works wine when Date.now() is using on both sides.
      if (data.expiresAt !== null && data.expiresAt < Date.now()) {
        removeItem(name)
      } else {
        return data.value
      }
    }
  } catch (e) {
    getLogger().warn(`Error when reading TTL item from localStorage, key: ${{ name }}`)
  }

  return null
}
export const setItemTtl = <Value = any>(name: string, value: Value, ttl?: number): void => {
  const data = {
    value: value,
    // Intentionally using Date.now() instead of server-synced time.
    // getItemTtl() / setItemTtl() are compared against each other. So everything
    // works wine when Date.now() is using on both sides.
    expiresAt: ttl ? Date.now() + ttl * 1000 : null,
  }
  setItem(name, JSON.stringify(data))
}

export const removeItem = (name: string): void => {
  if (isSupported()) {
    localStorage.removeItem(name)
  } else {
    getLogger().warn(`Using inMemoryStorage for removeItem, key: ${{ name }}`)
    delete inMemoryStorage[name]
  }
}

export const setItem = (name: string, value: string): void => {
  if (isSupported()) {
    localStorage.setItem(name, value)
  } else {
    getLogger().warn(`Using inMemoryStorage for setItem, key: ${{ name, value }}`)
    inMemoryStorage[name] = String(value)
  }
}

export const createEvent = <Value = any>(name: string, value: Value): Event => {
  const ev: any = document.createEvent('Event')
  ev.initEvent('storage', true, true)
  ev.key = name
  ev.newValue = value
  return ev
}
