/**
 * Checks that given error was thrown by fetch() function call.
 * It depends on custom fetch() implementation that marks all exceptions thrown with Error.isFetchError = true flag.
 */
export const isFetchError = deserializedError => {
  return !!(deserializedError && deserializedError.name === 'TypeError' && deserializedError.isFetchError === true)
}

/**
 * Checks that deserialized error is instance of GraphQL from `graphql` package
 * See https://github.com/graphql/graphql-js/blob/master/src/error/GraphQLError.js
 */
export const isGraphQLError = deserializedError => {
  return !!(deserializedError && 'locations' in deserializedError && 'path' in deserializedError)
}

/**
 * Checks that deserialized error is instance of ServerError from `apollo-link-http-common` package
 * See https://github.com/apollographql/apollo-link/blob/master/packages/apollo-link-http-common/src/index.ts
 */
export const isServerError = deserializedError => {
  return !!(
    deserializedError &&
    deserializedError.name === 'ServerError' &&
    'response' in deserializedError &&
    'result' in deserializedError &&
    'statusCode' in deserializedError
  )
}

/**
 * Checks if ServerErrors's result conforms following structure
 * {
 *     "errors": [
 *         {
 *             "message": "Error message here",
 *             "extensions": {
 *                 "code": "ERROR_CODE_HERE"
 *             }
 *         }
 *     ]
 * }
 */
export const hasServerErrorExtension = deserializedError => {
  return !!(
    deserializedError &&
    typeof deserializedError.result === 'object' &&
    deserializedError.result &&
    Array.isArray(deserializedError.result.errors) &&
    deserializedError.result.errors.reduce((acc, error) => {
      return acc && typeof error === 'object' && typeof error.message === 'string'
    }, true)
  )
}
