import { Middleware } from './Logger'

/**
 * Prefixes error thrown in GraphQL operation with operationName so it
 * has unique fingerprint
 */
const operationErrorMiddleware: Middleware = (rec, noemit, next) => {
  if (rec && rec.err && isOperationError(rec.err)) {
    const operationPrefix = `Operation ${rec.err.operationName}`

    // Enhance the message only once. Some errors may be logged multiple times.
    if (!~rec.err.message.indexOf(operationPrefix)) {
      rec.err.message = `${operationPrefix}: ${rec.err.message}`
    }
  }

  next(rec, noemit)
}

const isOperationError = deserializedError => {
  return !!(deserializedError && 'operationName' in deserializedError)
}

export default operationErrorMiddleware
