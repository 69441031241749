import { Middleware } from './Logger'

/**
 * Catches apollo-client's ApolloError and spawns new log record
 * for each graphQLError and networkError
 */
const apolloErrorMiddleware: Middleware = (rec, noemit, next) => {
  if (rec && rec.err && isApolloError(rec.err)) {
    const error = rec.err
    const { graphQLErrors, networkError } = error

    if (Array.isArray(graphQLErrors)) {
      // Spawn the new error for each graphQLError
      // Attach original error's stack
      graphQLErrors.forEach(graphQLError => {
        if (error.stack) {
          graphQLError.stack = error.stack
        }
        const spawnedRec = { ...rec, err: graphQLError }
        next(spawnedRec, noemit)
      })
    }

    if (networkError) {
      // Spawn the new error for networkError
      // Attach original error's stack
      if (error.stack) {
        networkError.stack = error.stack
      }
      const spawnedRec = { ...rec, err: networkError }
      next(spawnedRec, noemit)
    }

    return
  }

  next(rec, noemit)
}

/**
 * Checks that deserialized error is instance of ApolloError from apollo-client package
 * See https://github.com/apollographql/apollo-client/blob/master/packages/apollo-client/src/errors/ApolloError.ts
 */
const isApolloError = deserializedError => {
  return !!(
    deserializedError &&
    'graphQLErrors' in deserializedError &&
    'networkError' in deserializedError &&
    'extraInfo' in deserializedError &&
    'message' in deserializedError
  )
}

export default apolloErrorMiddleware
