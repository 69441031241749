export const createBunyanLog = (log: any) => {
  if (typeof log === 'string' || (typeof log === 'object' && log instanceof String)) {
    try {
      log = JSON.parse(log as string)
    } catch (e) {
      return log
    }
  }
  if (typeof log === 'object') {
    log.hostname = 'user-client'
    log.pid = 0
  }
  return log
}
