import { Severity } from '@sentry/browser'
import { deserializeError } from 'serialize-error'
import omit from 'lodash/omit'
import { DEBUG, INFO, WARN, ERROR, FATAL } from 'browser-bunyan'

import sentry from '../Sentry'

const { captureException, captureMessage } = sentry()

// Bunyan record-specific properties
const BUNYAN_RECORD_PROPERTIES = ['count', 'err', 'instance', 'level', 'levelName', 'msg', 'obj', 'time', 'url', 'v']

// Sentry record-specific properties
const SENTRY_RECORD_PROPERTIES = ['tags', 'user']

type Options = {
  writeCondition?: (record) => boolean
}

/**
 * Inspired by https://github.com/transcovo/bunyan-sentry-stream/blob/master/lib/SentryStream.js
 */
class SentryStream {
  options: Options = {
    writeCondition: () => true,
  }

  constructor(options: Options = {}) {
    this.options = { ...this.options, ...options }
  }

  getSentryLevel(record) {
    const level = record.level

    if (level >= FATAL) return Severity.Fatal
    if (level >= ERROR) return Severity.Error
    if (level >= WARN) return Severity.Warning
    if (level >= INFO) return Severity.Log
    if (level >= DEBUG) return Severity.Info

    // Bunyan's TRACE
    return Severity.Debug
  }

  write(record) {
    if (!this.options.writeCondition(record)) {
      return
    }

    const { err, msg, tags, user } = record
    const level = this.getSentryLevel(record)

    // Omit Bunyan record-specific properties – do not sent them to Sentry
    // Omit Sentry record-specific properties – handle them in special manner.
    // Consider the rest of record properties as Sentry extra content
    // https://docs.sentry.io/enriching-error-data/context/?platform=node#extra-context
    const recordExtra = omit(record, [...BUNYAN_RECORD_PROPERTIES, ...SENTRY_RECORD_PROPERTIES])
    const extra = { ...recordExtra, ...record.obj }

    // Using Sentry context structure
    // https://docs.sentry.io/enriching-error-data/context/?platform=node
    const context = { extra, level, tags, user }

    if (err) {
      // Attach Bunyan message into Sentry extras as msg field
      extra.msg = msg
      // Bunyan serializes the error to object
      // https://github.com/philmander/browser-bunyan/blob/master/packages/browser-bunyan/src/logger.js#L442
      captureException(deserializeError(err), context)
    } else {
      captureMessage(msg, level, context)
    }
  }
}

export default SentryStream
