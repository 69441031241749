// BaseLogger has incomplete TS types, so ignore it
import { Logger as BaseLogger } from 'browser-bunyan'
import { LoggerOptions as BaseLoggerOptions } from 'bunyan'

type Record = {
  [field: string]: any
}

export type Middleware = (rec: Record, noemit: boolean, next: (rec: Record, noemit: boolean) => void) => void

interface LoggerOptions extends BaseLoggerOptions {
  middlewares?: Middleware[]
}

class Logger extends BaseLogger {
  middlewares: Middleware[] = []

  constructor(options: LoggerOptions, _childOptions?: LoggerOptions, _childSimple?: boolean) {
    // @ts-ignore
    super(options, _childOptions, _childSimple)

    if (options.middlewares) {
      this.middlewares = options.middlewares
      // BaseLogger TS types missing, so ignore it
      // @ts-ignore
      delete this.fields.middlewares
    }
  }

  _emit(rec: Record, noemit: boolean) {
    return this._runMiddleware(rec, noemit)
  }

  _runMiddleware(rec: Record, noemit: boolean, i: number = 0) {
    // Last middleware passed, do emit
    if (i > this.middlewares.length - 1) {
      // @ts-ignore
      return super._emit(rec, noemit)
    }

    return this.middlewares[i](rec, noemit, (newRec, newNoemit) => {
      return this._runMiddleware(newRec, newNoemit, i + 1)
    })
  }
}

export default Logger

export function createLogger(options: LoggerOptions, _childOptions?: LoggerOptions, _childSimple?: boolean): any {
  return new Logger(options, _childOptions, _childSimple)
}
