import Logger from 'bunyan'
import { ConsoleFormattedStream, DEBUG, ERROR, INFO, stdSerializers, TRACE } from 'browser-bunyan'
import { serializeError } from 'serialize-error'
import { ServerStream } from '@browser-bunyan/server-stream'
import getConfig from 'next/config'

import ConsoleRawInlineStream from './logger/ConsoleRawInlineStream'
import SentryStream from './logger/SentryStream'
import apolloErrorMiddleware from './logger/apolloErrorMiddleware'
import ignoreErrorMiddleware from './logger/ignoreErrorMiddleware'
import operationErrorMiddleware from './logger/operationErrorMiddleware'
import serverErrorMiddleware from './logger/serverErrorMiddleware'
import { createLogger } from './logger/Logger'

const { NODE_CONFIG_ENV } = getConfig().publicRuntimeConfig

const getLogLevelBasedOnNodeEnv = () => {
  if (NODE_CONFIG_ENV === 'production') return INFO
  if (NODE_CONFIG_ENV === 'staging') return DEBUG
  if (NODE_CONFIG_ENV === 'development') return TRACE
  if (NODE_CONFIG_ENV === 'test') return TRACE
}

const errSerializer = err => {
  if (typeof DOMException !== 'undefined' && typeof DOMException === 'object' && err instanceof DOMException) {
    const fakeErr = new Error(err.message)
    fakeErr.name = err.name
    return fakeErr
  }

  return serializeError(err)
}

const streams = []

if ((process as any).browser) {
  // On client make formatted logs into console
  streams.push({
    level: getLogLevelBasedOnNodeEnv(),
    stream: new ConsoleFormattedStream({ logByLevel: true }),
  })
  // On client send logs to server
  streams.push({
    level: INFO,
    stream: new ServerStream({ url: '/logs', method: 'POST' }),
  })
} else {
  // On server make non-formatted raw-object logs into console
  streams.push({
    level: getLogLevelBasedOnNodeEnv(),
    stream: new ConsoleRawInlineStream(),
  })
}

streams.push({
  level: ERROR,
  stream: new SentryStream(),
})

const baseLogger = createLogger({
  name: 'user-client',
  middlewares: [apolloErrorMiddleware, serverErrorMiddleware, operationErrorMiddleware, ignoreErrorMiddleware],
  streams,
  serializers: {
    ...stdSerializers,
    err: errSerializer,
  },
  src:
    // Do not log source info in prod, it's very slow.
    // (https://github.com/philmander/browser-bunyan#log-record-fields)
    getConfig().publicRuntimeConfig.NODE_CONFIG_ENV !== 'production',
})

let logger = baseLogger

// Set context-aware logger
const setLogger = (options: Object) => {
  logger = baseLogger.child(options)
}

// Reset context-aware logger
const clearLogger = () => {
  setLogger({})
}

// Get context-aware logger
const getLogger = (): Logger => logger

export default getLogger

export { clearLogger, setLogger }
